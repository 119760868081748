<template>
  <div>
    <b-row>
    <b-col xl="8">
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar ref="previewEl"
          src="https://cdn-bldnd.nitrocdn.com/VBdVUFePdBRNlSOeKoHAbyOxXyWqduse/assets/static/optimized/rev-c928f4a/wp-content/uploads/2020/01/Oyster2_Rugged_Open.jpg"
          :text="avatarText(deviceData.name)" size="90px" rounded />
      </template>
      <b-form-group label="Cód/Nome" label-for="title">
        <h2 id="title" class="mb-1">
          {{ deviceData.name }}
        </h2>
      </b-form-group>

      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
        <b-row>

          <!-- Field: Name -->
          <b-col cols="12" md="3">
            <b-form-group label="Cód/Nome" label-for="name">
              <b-form-input id="name" v-model="deviceData.name" />
            </b-form-group>
          </b-col>

          <!-- Field: Model -->
          <b-col cols="12" md="3">
            <b-form-group label="Modelo" label-for="model">
              <b-form-input id="model" v-model="deviceData.brand" />
            </b-form-group>
          </b-col>

          <!-- Field: Serial -->
          <b-col cols="12" md="2">
            <b-form-group label="Serial" label-for="serial">
              <b-form-input id="serial" v-model="deviceData.year" />
            </b-form-group>
          </b-col>

          <!-- Field: Firmware -->
          <b-col cols="12" md="2">
            <b-form-group label="Firmware" label-for="firmware">
              <b-form-input id="firmware" v-model="deviceData.firmware" :disabled="true" />
            </b-form-group>
          </b-col>

          <!-- Field: DeviceKey -->
          <b-col cols="12" md="2">
            <b-form-group label="Chave Secreta" label-for="deviceKey">
              <b-form-input id="deviceKEy" v-model="deviceData.deviceKey" />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Status"
            label-for="device-status"
          >
            <v-select
              v-model="deviceData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="device-status"
            />
          </b-form-group>
        </b-col> -->

          <!-- Field: Role -->
          <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="User Role"
            label-for="device-role"
          >
            <v-select
              v-model="deviceData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="device-role"
            />
          </b-form-group>
        </b-col> -->

          <!-- Field: Associated -->
          <b-col cols="12" md="4">
            <b-form-group label="Associação" label-for="associated">
              <b-form-input id="associated" v-model="deviceData.company" />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="3">
            <b-form-group label="Status" label-for="status">
              <b-form-input id="status" v-model="deviceData.company" />
            </b-form-group>
          </b-col>

          <!-- Active -->
          <b-col cols="12" md="2">
            <b-form-group label="Ativo?" label-for="isactive">
              <b-form-radio-group id="isactive" v-model="deviceData.isActive"
                :options="[{text: 'SIM', value: true},{text:'NÃO', value:false}]" button-variant="outline-primary"
                buttons name="radios-btn-default" />
            </b-form-group>
          </b-col>

        </b-row>
    </b-form>

    </b-col>

    <b-col xl="4">
        <b-row>
          <h6 class="section-label mx-1 mb-2">
            Notificação
          </h6>
          <b-col cols="12" class="mb-2">
            <b-form-checkbox id="accountSwitch1" :checked="false" name="check-button" switch inline>
              <span>Enviar e-mail em caso de falha</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" class="mb-2">
            <b-form-checkbox id="accountSwitch2" :checked="false" name="check-button" switch inline>
              <span>Notificar perda linear de temperatura</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" class="mb-2">
            <b-form-checkbox id="accountSwitch3" :checked="true" name="check-button" switch inline>
              <span>Notificar grupo do telegram em caso de falha</span>
            </b-form-checkbox>
          </b-col>

          <!-- application switch -->
          <h6 class="section-label mx-1 mt-2">
            Atividade
          </h6>
          <b-col cols="12" class="mt-1 mb-2">
            <b-form-checkbox id="accountSwitch4" :checked="true" name="check-button" switch inline>
              <span>Desabilitar Dispositivo</span>
            </b-form-checkbox>
          </b-col>
          <!--/ application switch -->

        </b-row>
      </b-col>
    </b-row>

    <!-- PERMISSION TABLE -->
    <!-- <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->



    <!-- Action Buttons -->
    <!-- <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'">
      Salvar Alterações
    </b-button>
    <b-button variant="outline-secondary" type="reset" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$router.push('/devices')">
      Cancelar
    </b-button> -->

  </div>
</template>

<script>

import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader,
   BCardTitle, BFormCheckbox, BFormRadioGroup, BFormCheckboxGroup, BCardCode,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
// import devicesList from '../devices-list/devicesList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BCardCode,
    vSelect,
  },
  props: {
    deviceData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    state() {
      return Boolean(this.value)
    },
  },
  setup(props) {
    // const { resolveUserRoleVariant } = devicesList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.deviceData.avatar = base64
    })

    return {
      // resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
