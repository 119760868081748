<template>
  <b-form>
    <b-row>

        
<b-table
        class="position-relative"
        :items="deviceEvents"
        :fields="tableHistoryColumns"
        responsive
        primary-key="id"
        show-empty
        empty-text="Nenhum resultado encontrado."
      >

        <!-- Column: Name -->
        <template #cell(datetime)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.datetime }}</span>
          </div>
        </template>

        <!-- Column: Model -->
        <template #cell(eventType)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.eventType }}</span>
          </div>
        </template>
        
         <!-- Column: Name -->
        <template #cell(description)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">- - -</span>
          </div>
        </template>

        <!-- Column: Firmware -->
        <template #cell(value)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.value }}</span>
          </div>
        </template>
        
      </b-table>
      
      
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BTable, BPagination,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import router from '@/router'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import devicesList from '../devicesList'
import devicesStoreModule from '../devicesStoreModule'



export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BTable, BPagination,
  },
  setup() {
      
       // Table Handlers
        const tableHistoryColumns = [
            { key: 'datetime', sortable: true, label: 'Data' },
            { key: 'eventType', sortable: true, label: 'Evento' },
            { key: 'value', sortable: true, label: 'Valor' },
            { key: 'description', sortable: true, label: 'Descrição' },
            
        ]
  
      const deviceEvents = ref(null) 
      
      store.dispatch('app-device/fetchDeviceEvents', { id: router.currentRoute.params.id })
      .then(response => { deviceEvents.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          deviceEvents.value = undefined
        }
      })
      
    return {
        deviceEvents,
        tableHistoryColumns,
    }
  },
}

</script>

<style>

</style>