<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Configurações de hardware
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Birth Date -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Birth Date"
            label-for="birth-date"
          >
            <flat-pickr
              v-model="deviceDataInfo.dob"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d'}"
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Wifi -->
        <b-col
          cols="12"
          md="3"
          lg="2"
        >
          <b-form-group
            label="WiFi - SSID"
            label-for="ssid"
          >
            <b-form-input
              id="ssid"
              v-model="deviceDataInfo.firmware"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          lg="2"
        >
          <b-form-group
            label="WiFi - Senha"
            label-for="pass"
          >
            <b-form-input
              id="pass"
              v-model="deviceDataInfo.firmware"
            />
          </b-form-group>
        </b-col>

        <!-- Active -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Porta Serial"
            label-for="serialport"
          >
            <b-form-radio-group
              id="serialport"
              v-model="deviceDataInfo.firmware"
              :options="[{text: '9600', value: 9600},{text: '19200', value: 19200},{text: '115200', value: 115200},]"
              button-variant="outline-primary"
              buttons
              name="radios-btn-default"
            />
          </b-form-group>
        </b-col>

        <!-- Mode -->
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="Modo Operação"
            label-for="mode"
          >
            <b-form-radio-group
              id="mode"
              v-model="deviceDataInfo.firmware"
              :options="[{text: 'ONLINE', value: true},{text: 'AGENDA', value: false}]"
              button-variant="outline-primary"
              buttons
              name="radios-btn-default"
            />
          </b-form-group>
        </b-col>

        <!-- Agenda -->
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            v-if="deviceDataInfo.firmware === false"
            label="Intervalo (min)"
            label-for="agenda"
          >
            <b-form-input
              id="agenda"
              v-model="deviceDataInfo.firmware"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Website"
            label-for="website"
          >
            <b-form-input
              id="website"
              v-model="deviceDataInfo.website"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Language -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              v-model="deviceDataInfo.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languageOptions"
              :clearable="false"
              input-id="language"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Gender -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="deviceDataInfo.gender"
              :options="genderOptions"
              value="male"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Contact Options -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Contact Options"
            label-for="contact-options"
            label-class="mb-1"
          >
            <b-form-checkbox-group
              id="contact-options"
              v-model="deviceDataInfo.contactOptions"
              :options="contactOptionsOptions"
            />
          </b-form-group>
        </b-col> -->
      </b-row>

      <!-- Header: Personal Info -->
      <!-- <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Address
        </h4>
      </div> -->

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">

        <!-- Field: Address Line 1 -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Address Line 1"
            label-for="address-line-1"
          >
            <b-form-input
              id="address-line-1"
              v-model="deviceDataInfo.addressLine1"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Address Line 2 -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Address Line 2"
            label-for="address-line-2"
          >
            <b-form-input
              id="address-line-2"
              v-model="deviceDataInfo.addressLine2"
              placeholder="Los Santos"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Postcode -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Postcode"
            label-for="postcode"
          >
            <b-form-input
              id="postcode"
              v-model="deviceDataInfo.postcode"
              type="number"
              placeholder="597626"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: City -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="deviceDataInfo.city"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: State -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="State"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="deviceDataInfo.state"
              placeholder="Manhattan"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Country -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="deviceDataInfo.country"
              placeholder="United States"
            />
          </b-form-group>
        </b-col> -->
      </b-row>

      
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton,
  },
  setup() {
    const deviceDataInfo = ref({
      firmware: 'teste',
    })


    return {
      deviceDataInfo,
    }
  },
}
</script>

<style lang="scss">

</style>
