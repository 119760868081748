<template>
  <component :is="deviceData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="deviceData === undefined">
      <h4 class="alert-heading">
        Ops... Houve um problema.
      </h4>
      <div class="alert-body">
        Falha ao buscar dados do dispositivo. </br>:(
      </div>
    </b-alert>

    <b-tabs v-if="deviceData" pills>

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="CpuIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Dispositivo</span>
        </template>
        <device-edit-tab-device :device-data="deviceData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Parâmetros</span>
        </template>
        <device-edit-tab-settings class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Histórico</span>
        </template>
        <device-edit-tab-history class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>


    <b-form @submit.prevent="onSubmit()">
      <!-- Action Buttons -->
      <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'" type="submit">
        Salvar Alterações
      </b-button>
      <b-button variant="outline-secondary" type="reset" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$router.push('/devices')">
        Cancelar
      </b-button>

    </b-form>

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BButton, BForm, BCardCode,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import devicesStoreModule from '../devicesStoreModule'
import DeviceEditTabDevice from './DeviceEditTabDevice.vue'
import DeviceEditTabSettings from './DeviceEditTabSettings.vue'
import DeviceEditTabHistory from './DeviceEditTabHistory.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BForm,
    BCardCode,

    DeviceEditTabDevice,
    DeviceEditTabSettings,
    DeviceEditTabHistory,
  },
  directives: {
    Ripple,
  },
  setup({ emit }) {
    const deviceData = ref(null)
    const deviceEvents = ref(null)

    const DEVICE_APP_STORE_MODULE_NAME = 'app-device'

    // Register module
    if (!store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.registerModule(DEVICE_APP_STORE_MODULE_NAME, devicesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-device/fetchDevice', { id: router.currentRoute.params.id })
      .then(response => { deviceData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          deviceData.value = undefined
        }
      })

    store.dispatch('app-device/fetchDeviceEvents', { id: router.currentRoute.params.id })
      .then(response => { deviceEvents.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          deviceEvents.value = undefined
        }
      })


      const onSubmit = function() {
          const updatedeviceData = JSON.parse(JSON.stringify(deviceData.value))
          store.dispatch('app-device/updateDevice', { id: router.currentRoute.params.id, deviceData: updatedeviceData })
            .then(() => {
              // success
              this.$swal({
                title: 'Pronto!',
                text: 'Configurações salvas!',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch( // success
              this.$swal({
                title: 'Ooops!',
                text: 'Parece que houve um problema!',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }))
      }

    return {
      deviceData,
      deviceEvents,

      onSubmit,
    }
  },
}
</script>

<style>

</style>
